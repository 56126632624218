import React from 'react';
import Icon from '@ant-design/icons';
import { size } from './constants';

interface HomeProps {}

const Home: React.FC<HomeProps> = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			width={size}
			height={size}
			fill='currentColor'
		>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20z' />
		</svg>
	);
};

export const HomeIcon = (props: any) => <Icon component={Home} {...props} />;
